@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #2E9DC7;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}
